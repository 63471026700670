import { combineReducers } from '@reduxjs/toolkit';
import { withReduxStateSync } from 'redux-state-sync';
import limitModalReducer from './limitModal';
import disableUIReducer from './disableUIReducer';
import userPermissionsReducer from './userPermissionsReducer';
import modalReducer from './modalReducer';
import settingsReducer from './settingsReducer';
import instrumentPricingReducer from './instrumentPricingReducer';
import systemStatusReducer from './systemStatusReducer';
import latencyReducer from './latencyReducer';
import ordersReducer from './ordersReducer';
import assetsReducer from './assetsReducer';
import historicalOrdersReducer from './historicalOrdersReducer';
import layoutReducer from './layoutReducer';
import permissionReducer from './permissionsReducer';
import bookUpdateReducer from './bookUpdateReducer';
import rfqUpdateReducer from './rfqUpdateReducer';
import orderUpdate from './ordersUpdate';
import lockTradingReducer from './tradingReducers';
import tobUpdateReducer from './tobUpdateReducer';
import subscriptionReducer from './subscriptionsReducer';
import subscriptionRfqRfsReducer from './subscriptionsRfqRfsReducer';
import followClientsReducer from './followClientsReducer';
import creditCheckReducer from './creditCheckReducer';
import followOrdersReducer from './followOrdersReducer';
import tokenReducer from './tokenReducer';
import popoutReducer from './popoutReducer';
import stpReducer from './stpReducer';
import completedOrderReducer from './completedOrder';
import cancelAllOrdersReducer from './cancelAllOrdersReducer';
import openPopoutReducer from './openPopoutReducer';
import shadowUsernameReducer from './shadowUsernameReducer';
import restingOrderReducer from './restingOrderReducer';
import { SET_INITIAL_STATE } from '../actions/action_types';
import userDetailsReducer from './userDetailsReducer';
import sessionStatusReducer from './sessionStatusReducer';
import sessionServiceStartupReducer from './sessionServiceStartupReducer';
import websocketMonitorReducer from './websocketMonitorReducer';
// import browserPerfReducer from './browserPerfReducer';
import accountsReducer from './accountsReducer';
import rfqSubscriptionsReducer from './rfqSubscriptionsReducer';

const rootReducer = combineReducers({
  limitModal: limitModalReducer,
  user_permissions: userPermissionsReducer,
  disable_ui: disableUIReducer,
  modal: modalReducer,
  settings: settingsReducer,
  instrumentPricingReady: instrumentPricingReducer,
  system_status: systemStatusReducer,
  sessionStatus: sessionStatusReducer,
  sessionServiceStartup: sessionServiceStartupReducer,
  latency: latencyReducer,
  orders: ordersReducer,
  new_order_update: orderUpdate,
  assets: assetsReducer,
  historical_orders: historicalOrdersReducer,
  layout: layoutReducer,
  widgets_configuration: permissionReducer,
  book_update: bookUpdateReducer,
  rfq_update: rfqUpdateReducer,
  rfqSubscriptions: rfqSubscriptionsReducer,
  trading_locked: lockTradingReducer,
  tob: tobUpdateReducer,
  subscriptions: subscriptionReducer,
  subscriptionsRfqRfs: subscriptionRfqRfsReducer,
  followClients: followClientsReducer,
  followOrders: followOrdersReducer,
  creditCheck: creditCheckReducer,
  token: tokenReducer,
  popout: popoutReducer,
  stp: stpReducer,
  completed_order: completedOrderReducer,
  cancelAllOrders: cancelAllOrdersReducer,
  shadowUsername: shadowUsernameReducer,
  openPopouts: openPopoutReducer,
  restingOrder: restingOrderReducer,
  userDetails: userDetailsReducer,
  websocketMonitor: websocketMonitorReducer,
  // browserPerf: browserPerfReducer,
  accounts: accountsReducer,
});

const defaultReducer = (state, action) => {
  if (action.type === SET_INITIAL_STATE) {
    state = action.payload;
  }
  return rootReducer(state, action);
};

export default withReduxStateSync(defaultReducer);
