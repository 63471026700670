import sortBy from 'lodash/sortBy';
import { WhiteLabels, WhiteLabelOption } from '~types/common';
import wls from '../configs/wl';

const wl: WhiteLabels = wls;

export const getPlatformInfo = (): string[] => {
  const currUserName = sessionStorage.getItem('global_username');
  const pfLookupKey = `_currentEnv_${currUserName}`;
  const platform = localStorage.getItem(pfLookupKey) || '';

  // 1. platform based on local storage setting
  if (platform) {
    return [pfLookupKey, platform];
  }

  // 2. no any setting yet, then get default one based on host
  const hostname = window?.location?.hostname?.split('.');
  const [first, second] = hostname;
  if (wl[first]) {
    return [pfLookupKey, first];
  }

  if (wl[second]) {
    return [pfLookupKey, second];
  }

  return [pfLookupKey, 'localhost'];
};

export const getWlOptions = (): WhiteLabelOption[] => {
  const wlOptions: WhiteLabelOption[] = sortBy(
    Object.keys(wl).map((pl: string) => ({
      id: pl,
      label: `${wl[pl].APP_TITLE} - ${pl}`,
    })),
    'label'
  );
  return wlOptions;
};

// platForms as array
// easy handle for multi platform support
const checkPlatforms = (platForms: string[]) =>
  platForms.includes((window.APP_NAME || '').toLowerCase());

export const getHost = () => {
  // logic for setting correct APP_NAME and APP_TITLE
  const [, host] = getPlatformInfo();
  return wl[host];
};

// handel different theme before / after login
export const themeFor = (appNameAbs: string[], beforeLogin = true) => {
  const appName = getHost()?.APP_NAME;
  if (beforeLogin) {
    return (
      appNameAbs.includes(appName) && sessionStorage.getItem('token') === null
    );
  }

  return (
    appNameAbs.includes(appName) && sessionStorage.getItem('token') !== null
  );
};

// handel customize login submit btn bg color
export const customizeLoginBtnBgColor = (
  appNameAbs: string[],
  color: string
) => {
  if (!appNameAbs.includes(getHost()?.APP_NAME)) {
    return {};
  }

  return { backgroundColor: color };
};

export default checkPlatforms;

export const getIsAuth0 = () => {
  const [, host] = getPlatformInfo();
  return ['beta', 'wluat-v2'].includes(host);
};

export type Auth0MapInfoType = {
  domain: string;
  redirectUri?: string;
  clientId: string;
  organization?: string;
  audience: string;
};
export type Auth0Type = { [key: string]: Auth0MapInfoType };

const auth0Configs: Auth0Type = {
  beta: {
    domain: 'beta-latamfx.us.auth0.com',
    redirectUri: 'https://beta.latamfx.pro',
    clientId: 'V5RyaWfbWsytiGkayAG9RRbv6GRH2vuy',
    organization: 'org_mahgwTPuE6s0exwr',
    audience: 'https://beta-latamfx.us.auth0.com/api/v2/',
  },
  uat: {
    domain: 'uat-latamfx.us.auth0.com',
    redirectUri: 'https://uat.latamfx.pro',
    clientId: 'gUFCS7OJTDmqenVOd3QCZp08CbQtxkGJ',
    organization: 'org_flZESVdEhkcj0rYi',
    audience: 'https://uat-latamfx.us.auth0.com/api/v2/',
  },
  'wluat-v2': {
    domain: 'uat-v2-latamfx.us.auth0.com',
    redirectUri: 'https://wluat-v2.latamfx.pro',
    clientId: '663roGGeE4xJpbZtyhZmxixuIAQtkqHv',
    organization: 'org_zeYoA24CpSRiuC68',
    audience: 'https://uat-v2-latamfx.us.auth0.com/api/v2/',
  },
  localhost: {
    domain: 'beta-latamfx.us.auth0.com',
    redirectUri: 'http://localhost:3000',
    clientId: 'V5RyaWfbWsytiGkayAG9RRbv6GRH2vuy',
    organization: 'org_mahgwTPuE6s0exwr',
    audience: 'https://beta-latamfx.us.auth0.com/api/v2/',
  },
  banorte: {
    domain: 'prod-banorte.us.auth0.com',
    redirectUri: 'https://banorte.latamfx.pro',
    clientId: '4C3w8QHesRBR5dsRpPbXoZN7IyvT77P3',
    organization: 'org_tqNpF3HLMOs3R9yp',
    audience: 'https://prod-banorte.us.auth0.com/api/v2/',
  },
};

export const getAuth0Config = (): Auth0MapInfoType => {
  const [, host] = getPlatformInfo();
  return auth0Configs[host];
};

export const apiVersion = () => (getIsAuth0() ? 'v2' : 'v1');
